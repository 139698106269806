<script>
/* eslint-disable */
import DefaultMixin from '../mixin'
import RequerAtencao from "components/leiloes/components/dashboard/RequerAtencao.vue";
import UltimosLances from "components/leiloes/components/dashboard/UltimosLances.vue";
import LogLeilao from "components/leiloes/components/dashboard/LogLeilao.vue";
import RealtimeOnlines from "components/leiloes/components/dashboard/RealtimeOnlines.vue";
import LeilaoLive from "components/leiloes/components/dashboard/Live.vue";

export default {
  name: 'Leilao',
  inject: ['page'],
  mixins: [DefaultMixin],
  components: {LeilaoLive, RealtimeOnlines, LogLeilao, UltimosLances, RequerAtencao},
  data() {
    return {
      leilao: this.page.leilao
    }
  },
  computed: {
    menu() {
      return [
        {label: 'Controlador do Leilão', icon: 'fal fa-duotone fa-gamepad', route: 'leiloes.controlador'},
        {label: 'Painel do Leiloeiro', icon: 'fal fa-duotone fa-id-card', route: 'leiloes.tela-leiloeiro'},
        {label: 'Monitor de Comitentes', icon: 'fal fa-duotone fa-user-tie-hair', route: 'leiloes.monitor-comitente'},
        {label: 'Lances Automáticos', icon: 'fal fa-duotone fa-robot', route: 'leiloes.lances.automaticos'},
        {
          label: 'Tela para TV/Telão',
          icon: 'fal fa-duotone fa-desktop-alt',
          route: 'leiloes.apresentacao-telao-seletor'
        },
        {label: 'Transmissão por Vídeo', icon: 'fal fa-duotone fa-video', route: 'leiloes.realizacao.live.edit'},
        {
          label: 'Monitoramento',
          icon: 'erp-icon monitor',
          route: {name: 'leiloes.monitor', query: {leilao: this.leilao.id}}
        },
      ]
    }
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    clickMenu(m) {
      this.$router.push(m.route.name ? m.route : {name: m.route})
    }
  },
  meta: {
    title: 'Preparação',
    name: 'Preparação'
  }
}
</script>

<template>
  <div v-if="$route.name === 'leiloes.realizacao'" class="page-leilao-container">
    <div class="ev2-body leilao-dash realizacao">
      <div class="menu-opts">
        <a v-for="(m, key) in menu" :key="'menu-' + key" @click="clickMenu(m)">
          <i :class="m.icon"/>
          <span>{{ m.label }}</span>
        </a>
      </div>
      <div class="content-grid m-t-lg">
        <realtime-onlines style="margin-top: 0"/>
        <ultimos-lances/>
        <requer-atencao/>
        <leilao-live />
      </div>
      <div class="content-grid m-t-lg">
        <log-leilao/>
      </div>
    </div>
  </div>
  <router-view v-else />
</template>
