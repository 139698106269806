<template>
  <div class="ev2-box live">
    <div v-if="leilao.video" class="body no-overflow">
      <iframe width="100%" height="100%" :src="leilao.video"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; loop"
              allowfullscreen></iframe>
    </div>
    <div v-else class="body no-overflow flex items-center justify-center">
      <div>
        <div><i class="fa fa-duotone fa-video-camera m-b-xs" style="font-size: 32px" /></div>
        <div>Nenhuma transmissão ativa</div>
      </div>
    </div>
  </div>
</template>

<script>
import tabMixin from "components/leiloes/components/dashboard/tabMixin"

export default {
  name: "LeilaoLive",
  components: {
  },
  mixins: [tabMixin],
  computed: {
  },
  methods: {
  }
}
</script>
